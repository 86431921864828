import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom"
import styled from 'styled-components'
import theme from '../theme.json'
import ReactGA from 'react-ga4'
import { TbX } from "react-icons/tb";


const measurementID = 'G-3CE90C3G7T'
let isAnalyticsInitialized = false;

const initializeAnalytics = () => {
    if (!isAnalyticsInitialized) {
        ReactGA.initialize(measurementID);
        isAnalyticsInitialized = true;
    }
};

const updateGtag = (item, operation, value) => {
    if (window.gtag) {
        window.gtag(item, operation, {
            ad_storage: value,
            analytics_storage: value,
        });
    }
};

const deleteAnalyticsCookies = () => {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
        const cookieName = cookie.split('=')[0].trim();
        if (cookieName.startsWith('_ga') || cookieName.startsWith('_gid') || cookieName.startsWith('_gat')) {
            // Delete the cookie by setting its expiration date in the past
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
        }
    });
};

export default function CookiesConsent({ triggerDisplayCC }) {
    const [isDisplay, setIsDisplay] = useState(false);

    useEffect(() => {
        if (triggerDisplayCC) {
            setIsDisplay(true); // Open consent dialog based on prop
        }
    }, [triggerDisplayCC]);

    useEffect(() => {
        updateGtag('consent', 'default', 'denied'); // Default deny
        const consentValue = localStorage.getItem('analytics-consent');

        if (!consentValue) {
            setIsDisplay(true); // Show dialog if no consent is found
        } else if (consentValue === 'true') {
            initializeAnalytics();
            updateGtag('consent', 'update', 'granted'); // Grant consent if previously accepted
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('analytics-consent', 'true');
        initializeAnalytics();
        updateGtag('consent', 'update', 'granted'); // Grant consent
        setIsDisplay(false); // Close consent dialog
    };

    const handleRefuse = () => {
        //localStorage.setItem('analytics-consent', 'false');
        localStorage.removeItem('analytics-consent');
        updateGtag('consent', 'update', 'denied'); // Deny consent
        deleteAnalyticsCookies(); // Delete the cookies related to Google Analytics
        setIsDisplay(false); // Close consent dialog
    };


    return (
        isDisplay ? ReactDOM.createPortal(
            <Root>
                <Container>
                    <CloseButton
                        onClick={() => setIsDisplay(false)}
                    >
                        <TbX style={{ transform: 'scale(1.4)', color: theme.color.grey }} />
                    </CloseButton>
                    <TextContainer>
                        <Title>
                            Ce site web utilise des cookies.
                        </Title>
                        <Text>
                            Ils nous permettent d'améliorer votre expérience sur notre site.
                        </Text>
                        <Text>
                            Pour modifier vos préférences par la suite, cliquez sur le lien "Gestion des cookies" situé dans le pied de page.
                        </Text>
                    </TextContainer>
                    <ButtonsContainer>
                        <Button
                            onClick={handleAccept}
                            filled={true}
                        >
                            Accepter
                        </Button>
                        <Button
                            onClick={handleRefuse}
                        >
                            Refuser
                        </Button>
                    </ButtonsContainer>
                </Container>
            </Root>, document.body)
            :
            null
    )
}

const Root = styled.div`
    position: fixed;
    z-index: 2000;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    //height: 100%;
    //width: 100%;
    //background-color: rgb(240, 240, 240, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Background = styled.div`
    flex: 1;
    width: 100%;
    opacity: 0.5;
`

const Container = styled.div`
    max-width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: ${theme.color.white};
    border: solid 1px ${theme.color.lightGrey};
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const TextContainer = styled.div`
    color: ${theme.color.grey};
    display: flex;
    flex-direction: column;
    gap: 10px;
    //align-items: center;
    //justify-content: center;
`

const Title = styled.div`
    font-weight: bold
`

const CloseButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Text = styled.div`

`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
`

const Button = styled.div`
    padding: 5px;
    font-weight: bold;
    color: ${props => props.filled ? theme.color.white : theme.color.grey};
    background-color: ${props => props.filled ? theme.color.grey : theme.color.white};
    border: solid 2px ${theme.color.grey};
    border-radius: 10px;
    cursor: pointer;
`