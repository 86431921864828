import React, { useEffect, useState, useRef, lazy, Suspense } from 'react'
import styled from 'styled-components'
import theme from '../theme.json'


export default function TextPage({ textContent }) {

    const MdxContent = lazy(() => {
        return import(`../content/${textContent}.mdx`).catch((err) => {
            return import(`../content/default.mdx`)
        })
    })

    return (
        <Page>
            <Suspense fallback={<div>Loading...</div>}>
                <MdxContent />
            </Suspense>
        </Page>

    )
}


const Page = styled.div`
    flex: 1;
    margin-top: 8vh;
    min-height: 92vh;
    padding: 0vh 20vw 5vh 20vw;
    overflow: auto;
    //display: flex;
    flex-direction: column;
    @media (max-width: 1100px) {
        padding: 5vh 12vw 5vh 12vw;
    };
    p {
        color: ${theme.color.grey};
    };
    h1, h2, h3, h4, h5, h6 {
        color: ${theme.color.primary};
    };
    h1 {
        text-align: center;
        margin: 8vh;
    };
`