import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home';
import Features from './components/Features';
import Aboutus from './components/Aboutus';
import Contact from './components/Contact';
import AppBar from './components/AppBar'
import { AnimatePresence } from 'framer-motion'
import { BreakpointProvider } from 'react-socks';
import styled from 'styled-components'
import theme from './theme.json'
import Footer from './components/Footer';
import TextPage from './components/TextPage';

///////////////////////////////////
/// Style-components
///////////////////////////////////
const Main = styled.div`
    @media (max-width: 700px) {
        //margin-top:7vh
    }
`

///////////////////////////////////
/// Constant
///////////////////////////////////
/*const routesList = [
    { url: '/', element: <Home /> },
    { url: '/fonctionnalites', element: <Features /> },
    { url: '/apropos', element: <Aboutus /> },
    { url: '/contact', element: <Contact /> },
]*/

///////////////////////////////////
/// Component
///////////////////////////////////
export default function RoutesSwitch() {

    const [featuresTabMode, setFeaturesTabMode] = useState('Web')

    return (
        <BreakpointProvider>
            <div className='App' style={{ overflowX: 'hidden', backgroundColor: theme.color.white }}>
                <AppBar />
                <Main>
                    <AnimatePresence >
                        <Routes>
                            {/*routesList.map((item) => (
                                <Route key={item.url} path={item.url} element={item.element} />
                            ))*/}
                            <Route key='/' path='/' element={<Home setFeaturesTabMode={(event) => setFeaturesTabMode(event)} />} />
                            <Route key='/fonctionnalites' path='/fonctionnalites' element={<Features featuresTabMode={featuresTabMode} setFeaturesTabMode={(event) => setFeaturesTabMode(event)}/>} />
                            <Route key='/apropos' path='/apropos' element={<Aboutus />} />
                            <Route key='/contact' path='/contact' element={<Contact />} />
                            <Route key='/mentionslegales' path='/mentionslegales' element={<TextPage textContent='legalNotices'/>} />
                            <Route key='/politiquedeconfidentialite' path='/politiquedeconfidentialite' element={<TextPage textContent='privacyPolicy' />} />
                        </Routes>
                    </AnimatePresence>
                </Main>
                <Footer />
            </div>
        </BreakpointProvider>
    )

}