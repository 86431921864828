import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import theme from '../theme.json'
import Suivi from '../medias/videos/suivi.mp4'
import Enrichissement from '../medias/videos/enrichissement.mp4'
import Filtres from '../medias/videos/filtres.mp4'
import Render from '../medias/videos/render.mp4'
import Decoupe from '../medias/videos/decoupe.mp4'
import Viewer from '../medias/videos/viewer.mp4'
import Dashboard from '../medias/videos/dashboard.mp4'
import Tableur from '../medias/videos/tableur.mp4'
import MobileView from '../medias/videos/mobile-view.mp4'
import MobileTasks from '../medias/videos/mobile-tasks.mp4'

///////////////////////////////////
/// Style-components
///////////////////////////////////
const openRoot = keyframes`
    0% {max-height: 15%}
    100% {max-height: 100%}
`

const closeBody = keyframes`
    0% {height: 100px}
    100% {height: 0px}
`

const Root = styled.div`
    display: flex;
    flex-direction: column;
    direction:ltr;
    animation: 1s ${props => props.selected && openRoot} ease-in-out;
`

const Hover = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem 5% 0.5rem 5%;
    overflow:hidden;
    box-shadow: ${props => props.selected && 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'};
    &:hover {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
`
const Body = styled.div`
    padding-bottom: ${props => props.selected ? '1rem' : '0px'};
    font-size: 0.9rem;
    overflow: hidden;
    height: ${props => props.selected ? '100%' : '0px'};
    color: ${theme.color.grey};
    text-justify: inter-character;
    animation: 0.3s ${props => props.unselected && closeBody} ease-in-out forwards;
`
const Divider = styled.div`
    width: 100%;
    height: 0.5px;
    background-color: ${theme.color.lightGrey};
`


///////////////////////////////////
/// Component
///////////////////////////////////
const FeatureItem = ({ isLast, title, text, selected, unselected, onClick }) => {

    return (
        <Root selected={selected} onClick={onClick}>
            <Hover selected={selected}>
                <Title
                    style={{ marginBottom: '0.3rem' }}
                    weight='bold'
                    color={selected ? theme.color.secondary : theme.color.primary}
                    size='1rem' >
                    {title}
                </Title>
                <Body selected={selected} unselected={unselected}>
                    {text}
                </Body>
            </Hover>
            {
                isLast && <Divider />
            }
        </Root>
    )
}


///////////////////////////////////
/// Style-components
///////////////////////////////////
const lineGrow = keyframes`
    0% {width: 0%}
    100% {width: 80%}
`

const easeTitle = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
`

const Main = styled.div`
    display: flex;
    height: 92vh;
    margin-top: 8vh;
    width: 100%;
    padding: 0% 15% 0% 15%;
    @media (max-width: 1350px) {
        padding: 0% 7% 0% 7%;
    }
    align-items: center;
    flex-direction: column;
    justify-content: center;
`

const TitleContainer = styled.div`
    height: 10%;
    display: flex;
    align-items: center;
    justify-content:center;
`

const DividerM = styled.div`
    border-bottom: 1px solid ${theme.color.primary};
    animation: 0.5s ${lineGrow} 0s ease-out forwards;
`
const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 80%;
    padding: 0% 0% 5% 0%;
    padding-top: ${props => props.isMobile && '1%'};
    gap: ${props => props.isMobile && '10%'} ;
    @media (max-width: 860px) {
        flex-direction: column;
        gap: 5%;
    };
    @media (max-width: 1350px) {
        padding: 0% 0% 5% 0%;
    };
`
const FeaturesContainer = styled.div`
    width: ${props => props.isMobile ? '30%' : '40%'};
    overflow: auto;
    display: flex;
    flex-direction: column;
    direction: rtl;
    justify-content: start;
    margin-right: 1.7em;
    padding: 0.2em 0 0.2em 0;
    margin-top: 1em;
    background-color: ${theme.color.white};
    margin-left: ${props => props.isMobile && '-12%'};
    @media (max-width: 860px) {
        order: 2;
        width: 80%;
        margin-left: 0px;
    }
    @media (min-width: 860px) {
        aspect-ratio: 1/1;
    }
`

const Video = styled.div`
    aspect-ratio: ${props => props.isMobile ? 43 / 96 : 4 / 3};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: ${props => props.isMobile ? '20px' : '10px'};
    border: ${props => props.isMobile && `solid 4px ${theme.color.black}`};
    border-top: ${props => props.isMobile && `solid 20px ${theme.color.black}`};
    border-bottom: ${props => props.isMobile && `solid 20px ${theme.color.black}`};
    height: ${props => props.isMobile ? '100%' : 'fit-content'};
    width: ${props => props.isMobile ? 'fit-content' : '50%'};
    overflow: ${props => props.isMobile && 'hidden'};
    @media (max-width: 470px) {
        order: 1;
        height: fit-content;
        max-height: 50%;
        min-width: ${props => !props.isMobile && '90%'};
        max-width: ${props => !props.isMobile && '90%'};
        width: fit-content;
    }
    @media (min-width: 470px) and (max-width: 860px) and (min-height: 1110px) {
        order: 1;
        height: fit-content;
        max-height: 50%;
        min-width: ${props => !props.isMobile && '70%'};
        max-width: ${props => !props.isMobile && '70%'};
        width: fit-content;
    }
`
const Title = styled.div`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    text-align: ${props => props.type && 'center'};
    animation-name: ${props => props.type && easeTitle};
    animation-duration: 1s;
    animation-timing-function: ease-out;
    @media (max-width: 860px) {
        font-size: ${props => props.type && '1.1rem'};
    };
`

const TabContainer = styled.div`
    height: 10%;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1350px) {
        padding-bottom: 1rem;
    };
`

const Tabs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px ${theme.color.primary};
    border-radius: 2rem;
`

const Tab = styled.div`
    height: 100%;
    padding: 0.2rem 1rem 0.2rem 1rem;
    background-color: ${props => props.selected && theme.color.primary};
    color: ${props => props.selected ? theme.color.white : theme.color.primary};
    font-size: 1rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 860px) {
        max-height: 25px;
        max-width: 4rem;
        font-size: 0.9rem;
    };
`


///////////////////////////////////
/// Constant
///////////////////////////////////

const webFeaturesList = [
    { title: 'Suivi de chantier', text: "Pointez les ouvrages, affichez l'avancement de votre chantier en 3D grâce aux multiples options de configuration (couleurs, transparence, délai, type de dates...)" },
    { title: 'Enrichissement', text: "Créez des paramètres utilisateur pour enrichir les données de vos modèles. Les applications sont nombreuses: type de béton coulé, bon de livraison, mode constructif réel, empreinte carbone..." },
    { title: 'Filtres', text: "Les filtres de vue vous permettent de visualiser les données de vos maquettes. Basés sur les couples paramètres/valeurs de chaque objet, vous pouvez également utiliser les paramètres utilisateur." },
    { title: 'Communication', text: "Grâce à l'export PDF vous partagez vos vues de façon simple et rapide. Plus besoin d'ouvrir un logiciel de modélisation et de savoir l'utiliser pour éditer des vues 3D." },
    { title: 'Découpe 3D', text: "Avec l'outil découpe, divisez vos objets pour coller au maximum à la réalité du chantier. Le suivi des arrêts de bétonnage est précis et les quantitatifs associés sont recalculés." },
    { title: 'Tableaux de bord', text: "Créez des graphiques liés aux données de la maquette. Ils vous donnent un accès simple et rapide aux grandes lignes de la santé de votre chantier." },
    { title: 'Tableur', text: "Utilisez l'outil tableur pour créer des documents complexes. Suivi de béton, de ferraille, d'heures, de budget, d'empreinte carbone (etc) peuvent être maintenant automatisés. Vous dégagerez du temps pour des tâches à plus grande valeur ajoutée que la saisie de données." },
    { title: 'Viewer', text: "Le viewer vous donne accès aux outils de base pour naviguer facilement dans vos modèles. Prenez des côtes, des côtes d'altimétrie, faites des coupes, cachez et isolez des objets." }
]

const AppFeaturesList = [{ title: 'Visualisation & Pointage', text: "Pointez les ouvrages directement sur votre mobile. Visualisez les objets en 3D et en plan. Chaque objet possède des tâches. Renseignez des dates de réalisation, des commentaires, des photos, ou ajoutez de nouvelles tâches." },
{ title: 'Gestion des tâches', text: "Les tâches sont accessibles par filtrage ou grâce au calendrier (dates de prévision). Elles sont configurables par type d'ouvrage (ex: Dalle CEP - coffrage, ferraillage, coulage). Les données sont disponibles dans la plateforme web pour être valorisées. " },
]

const videos = [Suivi, Enrichissement, Filtres, Render, Decoupe, Dashboard, Tableur, Viewer]

const mobileVideos = [MobileView, MobileTasks]
///////////////////////////////////
/// Component
///////////////////////////////////
export default function Features(props) {
    const [selected, setSelected] = useState(0)
    const [unselected, setUnselected] = useState(null)

    const changeTabMode = (mode) => {
        props.setFeaturesTabMode(mode)
        setSelected(0)
        setUnselected(null)
    }

    return (
        <Main>
            <TitleContainer>
                <Title type='main' size='1.3rem' color={theme.color.primary} weight='bold'>
                    Du BIM utile pour vos chantiers.
                </Title>
            </TitleContainer>
            <DividerM />
            <TabContainer>
                <Tabs>
                    <Tab onClick={() => changeTabMode('Web')} selected={props.featuresTabMode === 'Web'}>Web</Tab>
                    <Tab onClick={() => changeTabMode('App')} selected={props.featuresTabMode === 'App'}>Mobile</Tab>
                </Tabs>
            </TabContainer>
            <Container isMobile={props.featuresTabMode === 'App'}>
                <FeaturesContainer isMobile={props.featuresTabMode === 'App'}>
                    {props.featuresTabMode === 'Web' &&
                        webFeaturesList.map((feature, index) => (
                            <FeatureItem
                                key={feature.title}
                                onClick={() => {
                                    if (selected !== index) {
                                        setUnselected(selected)
                                        setSelected(index)
                                    }
                                }}
                                isLast={index === webFeaturesList.length - 1 ? false : true}
                                title={feature.title}
                                text={feature.text}
                                selected={selected === index && true}
                                unselected={unselected === index && true}
                            />
                        ))
                    }
                    {props.featuresTabMode === 'App' &&
                        AppFeaturesList.map((feature, index) => (
                            <FeatureItem
                                key={feature.title}
                                onClick={() => {
                                    if (selected !== index) {
                                        setUnselected(selected)
                                        setSelected(index)
                                    }
                                }}
                                isLast={index === webFeaturesList.length - 1 ? false : true}
                                title={feature.title}
                                text={feature.text}
                                selected={selected === index && true}
                                unselected={unselected === index && true}
                            />
                        ))
                    }
                </FeaturesContainer>
                <Video isMobile={props.featuresTabMode === 'App'}>
                    <video
                        autoPlay muted loop preload="true"
                        src={props.featuresTabMode === 'Web' ? videos[selected] : mobileVideos[selected]}
                        width="100%" height='100%'
                        style={{
                            borderRadius: 10,
                            transform: props.featuresTabMode === 'App' && 'scale(2.85) translate(-0.1%, -0.1%)'
                        }} />
                </Video>
            </Container>
        </Main>
    )

}