import React, { useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import theme from '../theme.json'
import { useNavigate } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import Suivi from '../medias/Suivi.png'
import SuiviPlan from '../medias/SuiviPlan.png'
import Organisation from '../medias/Organisation2.png'
import BIM from '../medias/BIM2.png'
import Capture from '../medias/Capture.PNG'
import CaptureApp1 from '../medias/CaptureApp1.jpg'
import CaptureApp2 from '../medias/CaptureApp2.jpg'


///////////////////////////////////
/// Style-components
///////////////////////////////////
const Page = styled.div`
    flex: 1;
    margin-top: 8vh;
    padding: 0 15vw 0 15vw;
    overflow: auto;
`

const Landing = styled.div`
    height: 92vh;
    display: flex;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
`

const LandingLeft = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //background-color: blue;
    @media (max-width: 1100px) {
        height: 50%;
        width: 100%;
    }
    @media (max-width: 300px) {
        height: 150vh;
    }
`

const easeIcon = keyframes`
    0% {opacity:0}
    100% {opacity:1}
`

const LandingTitle = styled.div`
    font-weight: bold;
    font-size: 2.9rem;
    color: ${theme.color.primary};
    animation: ${easeIcon} 0.5s forwards;
    @media (max-width: 1100px) {
        font-size: 2rem;
    }
`

const LandingText = styled.div`
    margin-top: 3rem;
    font-size: 1.2rem;
    width: 100%;
    color: ${theme.color.grey};
    animation: ${easeIcon} 1s forwards;
    @media (max-width: 1100px) {
        font-size: 1rem;
        margin-top: 2rem;
    }
`

const changeBackgroundColor = keyframes`
    0% {background-color: ${theme.color.primary}}
    100% {background-color: ${theme.color.secondary}}
`

const LandingButton = styled.div`
    width: fit-content;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${theme.color.white};
    background-color: ${theme.color.primary};
    text-align: center;
    border-radius: 2rem;
    cursor: pointer;
    animation: ${easeIcon} 1s forwards;
    &:hover {
        animation: ${changeBackgroundColor} 1s forwards;
    }
    @media (max-width: 1100px) {
        width: auto;
        font-size: 1rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
    }
`

const LandingImage = styled.img`
    width: 100%;
    animation: ${easeIcon} 2s forwards;
    //background-color: blue;
    @media (max-width: 1100px) {
        height: 100%;
        object-fit: contain;
    }
`

const IconsParagraph = styled.div`
    display: flex;
    gap: 10vw;
    justify-content: center;
    @media (max-width: 500px) {
        flex-direction: column;
    }
    margin-top: 6rem;
    margin-bottom: 4rem;
`

const Icon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: ${easeIcon} 2s forwards;
    animation-delay: ${props => `${props.index / 2}s`};
`

const Image = styled.img`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
`

const Name = styled.div`
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${theme.color.primary};
`

const VideoParagraph = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`

const Ul = styled.ul`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    @media (max-width: 500px) {
        width: 100%;
    }
`

const Li = styled.li`
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: ${theme.color.black};
    @media (max-width: 1100px) {
        font-size: 1rem;
    }
`

const CaptureImg1 = styled.img`
    max-height: 20rem;
    max-width: 50%;
    margin-left: 5%;
    margin-bottom:2rem;
    border: solid 1px ${theme.color.lightGrey};
    border-radius: 1rem;
    @media (max-width: 500px) {
        max-width: 100%;
        margin-left: 0;
    }
`

const CaptureImg2Container = styled.div`
    margin-right: 5%;
    margin-bottom: 2rem;
    gap: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
        margin-right: 0;
    }
`

const CaptureImg2 = styled.img`
    max-height: 20rem;
    max-width: 50%;
    //margin-right: 10%;
    border: solid 4px ${theme.color.black};
    border-top: solid 20px ${theme.color.black};
    border-bottom: solid 20px ${theme.color.black};
    border-radius: 1rem;
`

const FeaturesButton = styled.div`
    margin: auto;
    margin-bottom: 4rem;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${theme.color.white};
    background-color: ${theme.color.primary};
    text-align: center;
    border-radius: 2rem;
    cursor: pointer;
    &:hover {
        animation: ${changeBackgroundColor} 1s forwards;
    }
    @media (max-width: 1100px) {
        font-size: 1rem;
    }
`

const lineGrow = keyframes`
    0% {width: 0%}
    100% {width: 80%}
`

const HContainer = styled.div`
    display: flex; 
    width: 100%;
    justify-content: center;
`

const HDivider = styled.div`
    height: 1px;
    background: #63005f;
    animation: 0.5s ${lineGrow} 0s ease-out forwards;
`

const Title = styled.div`
    height: 15%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content:center;
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    text-align: ${props => props.type && 'center'};
    @media (max-width: 860px) {
        font-size: ${props => props.type && '1.1rem'};
    };
`


///////////////////////////////////
/// Constant
///////////////////////////////////
const iconsList = [
    { icon: Organisation, name: 'Suivi' },
    { icon: SuiviPlan, name: 'Organisation' },
    { icon: BIM, name: 'BIM' },
]


///////////////////////////////////
/// Component
///////////////////////////////////
export default function Home(props) {

    const navigate = useNavigate()
    const refPage = useRef()

    const handleFeaturesButton = (type) => {
        props.setFeaturesTabMode(type)
        navigate('/fonctionnalites')
    }

    return (
        <Page id='refPage' ref={refPage}>
            <Landing>
                <Breakpoint style={{height: '50%', padding: '1rem'}} customQuery="(max-width: 1100px)">
                    <LandingImage src={Suivi} />
                </Breakpoint>
                <LandingLeft>
                    <LandingTitle>Clever Unique Equipment</LandingTitle>
                    <LandingText>
                        Clune est une plateforme web d’aide au suivi et à l’organisation de chantier basée sur le BIM.
                        Elle donne un maximum d’informations aux opérationnels pour qu’ils puissent prendre des décisions éclairées face aux aléas.
                    </LandingText>
                    <LandingButton onClick={() => navigate('/contact')}>
                        Réservez une démo
                    </LandingButton>
                </LandingLeft>
                <Breakpoint style={{display: 'flex', alignItems: 'end', justifyContent: 'right'}} customQuery="(min-width: 1100px)">
                    <LandingImage src={Suivi} />
                </Breakpoint>
            </Landing>
            {/*<Texte>
                Clune est une plateforme web d’aide au suivi et à l’organisation de chantier basée sur le BIM.
                Elle donne un maximum d’informations aux opérationnels pour qu’ils puissent prendre des décisions éclairées face à un aléa.
    </Texte>*/}
            <IconsParagraph>
                {iconsList.map((item, index) => (
                    <Icon key={item.name} index={index}>
                        <Image src={item.icon} style={{ height: 100 }} alt={item.name} />
                        <Name>{item.name}</Name>
                    </Icon>
                ))}
            </IconsParagraph>
            <HContainer>
                <HDivider />
            </HContainer>
            <Title
                type='main'
                size='1.5rem'
                color={theme.color.primary}
                weight='bold'
            >
                Connectez les données avec le terrain.
            </Title>
            <VideoParagraph>
                <Ul>
                    <Li>
                        <span style={{ color: theme.color.primary, fontWeight: "bold" }}>Créez</span> vos rendus avec les données qui vous intéressent.
                    </Li>
                    <Li>
                        Un même modèle pour <span style={{ color: theme.color.primary, fontWeight: "bold" }}>échanger</span> et partager avec tous.
                    </Li>
                    <Li>
                        <span style={{ color: theme.color.primary, fontWeight: "bold" }}>Automatisez</span> vos documents de suivi et
                        <span style={{ color: theme.color.primary, fontWeight: "bold" }}> exploitez</span> vos données plus facilement.
                    </Li>
                </Ul>
                <CaptureImg1 marginLeft={true} src={Capture} style={{ objectFit: 'scale-down' }} alt={'item.name'} />
            </VideoParagraph>
            <HContainer>
                <FeaturesButton
                    onClick={() => handleFeaturesButton('Web')}
                >
                    Découvrez les fonctionnalités
                </FeaturesButton>
            </HContainer>
            <HContainer>
                <HDivider />
            </HContainer>
            <Title
                type='main'
                size='1.5rem'
                color={theme.color.primary}
                weight='bold'
            >
                Accédez à vos modèles sur différents appareils.
            </Title>
            <VideoParagraph>
                <CaptureImg2Container>
                    <CaptureImg2 marginRight={true} src={CaptureApp1} style={{ objectFit: 'scale-down' }} alt={'item.name'} />
                    <CaptureImg2 marginRight={true} src={CaptureApp2} style={{ objectFit: 'scale-down' }} alt={'item.name'} />
                </CaptureImg2Container>
                <Ul>
                    <Li>
                        <span style={{ color: theme.color.primary, fontWeight: "bold" }}>Visualisez</span> vos maquettes partout sur votre chantier.
                    </Li>
                    <Li>
                        <span style={{ color: theme.color.primary, fontWeight: "bold" }}>Pointez</span> et
                        <span style={{ color: theme.color.primary, fontWeight: "bold" }}> organisez</span> facilement vos tâches à réaliser.
                    </Li>
                </Ul>
            </VideoParagraph>
            <HContainer>
                <FeaturesButton
                    onClick={() => handleFeaturesButton('App')}
                >
                    Découvrez l'application
                </FeaturesButton>
            </HContainer>
        </Page>
    )

}