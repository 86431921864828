import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../theme.json'
import CookiesConsent from './CookiesConsent'
import Logo from '../medias/Logo_inverse_C_300px.png'
import { BsLinkedin } from 'react-icons/bs'
import { BsYoutube } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'

const logosList = [
    { logo: <BsLinkedin style={{ transform: 'scale(2)', color: theme.color.white }} />, url: 'https://www.linkedin.com/company/clune/' },
    { logo: <BsYoutube style={{ transform: 'scale(2)', color: theme.color.white }} />, url: 'https://www.youtube.com/channel/UCvFDb9kjRuCYFNs35sY7paw' },
    { logo: <MdEmail style={{ transform: 'scale(2)', color: theme.color.white }} />, url: 'mailto:contact@clune.fr' },
]

const buttonsList = [
    { url: '/', name: 'Accueil' },
    { url: '/fonctionnalites', name: 'Fonctionnalités' },
    { url: '/apropos', name: 'A propos' },
    { url: '/contact', name: 'Contact' },
]


export default function Footer({ }) {

    const [triggerDisplayCC, setTriggerDisplayCC] = useState(0)
    const navigate = useNavigate()

    const openCookiesConsent = () => {
        setTriggerDisplayCC(triggerDisplayCC + 1)
    }

    const legalButtonsList = [
        { name: '©Clune', onClick: () => { } },
        { name: 'Mentions légales', onClick: () => navigate('/mentionslegales') },
        { name: 'Politique de confidentialité', onClick: () => navigate('/politiquedeconfidentialite') },
        { name: 'Gestion des cookies', onClick: openCookiesConsent },
    ]

    return (
        <>
            <Container>
                <BodyContainer>
                    {buttonsList.map((item, index) => (
                        index === 0 ?
                            <ColumnContainer
                                key={item.name}
                            >
                                <img src={Logo}
                                    alt="logo-Clune"
                                    style={{ maxWidth: '20vw', cursor: 'pointer' }}
                                    onClick={() => navigate(item.url)}
                                />
                                <ColumnBody>
                                    <Logos>
                                        {logosList.map((item) => (
                                            <a key={item.url} href={item.url} target='_blank' rel='noreferrer'>
                                                {item.logo}
                                            </a>
                                        ))}
                                    </Logos>
                                </ColumnBody>
                            </ColumnContainer>
                            :
                            <ColumnContainer
                                key={item.name}
                            >
                                <ColumnTitle
                                    onClick={() => navigate(item.url)}
                                >
                                    {item.name}
                                </ColumnTitle>
                                <ColumnBody>
                                </ColumnBody>
                            </ColumnContainer>
                    ))}
                </BodyContainer>
                <FootContainer>
                    {legalButtonsList.map((item) => (
                        <TextButton
                            key={item.name}
                            onClick={item.onClick}
                        >
                            {item.name}
                        </TextButton>
                    ))}

                </FootContainer>
            </Container>
            <CookiesConsent
                triggerDisplayCC={triggerDisplayCC}
            />
        </>

    )
}

const Container = styled.div`
    width: 100%;
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    display: flex;
    flex-direction: column;
`

const BodyContainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    border-bottom: solid 1px ${theme.color.white};
    @media (max-width: 900px) {
        flex-direction: column;
    }
`

const FootContainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
`

const ColumnContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const ColumnTitle = styled.div`
    display: flex;
    font-weight: bold;
    cursor: pointer
`

const ColumnBody = styled.div`
    display: flex;
    flex-direction: column;
`

const TextButton = styled.div`
    width: fit-content;
    cursor: pointer;
`

const Logos = styled.div`
    //height: 25%;
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5vw;
    width: 100%;
    @media (max-width: 500px) {
        gap: 20vw;
        margin-top: 2vh
    }
`